import React from 'react';
import TitleButton from '../../components/TitleButtonEdit';
import InputTextAttribute from '../../components/InputTextAttribute';
import SelectAtribute from '../../components/SelectAttribute';
import UpFilePDF from '../../components/UpFilePDF';
// import SelectWithInput from '../../components/SelectWithInput';
import TrixDescription from '../../components/TrixDescription';
import ModalCheck from '../modal_check/ModalCheck';
import { toast } from 'react-toastify';
// import sizes from '../../../api/sizeTypeFlexline.json'
// import packages from '../../../api/packageTypeFlexline.json'
// import spaces from '../../../api/spaceTypeFlexline.json'

import SwitchRadioButton from '../../components/SwitchRadioButton';
import 'react-toastify/dist/ReactToastify.css';
import './Sheet.css';
import RelatedEdgebands from "../technical_products/TechnicalProducts";
import { connect } from 'react-redux';
import { getBrandsData, getColors, getMaterials, getSizeCodes, getSpaceCodes, technicalOptions  } from '../../../api/APIGet'
import WebName from '../../new/webname/WebName'


class Sheet extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: true,
      modalcheck: false,
      modalcheckData: [],
      sku: '',
      html_text_descript: '',
      brands: [],
      sizes: [],
      packages: [],
      spaces: [],
      colors: [],
      materials: [],
      spacecodes: [],
      sizecodes: []
    };
    this.inputEnabler = this.inputEnabler.bind(this);
    this.check_data = this.check_data.bind(this);
    this.changeStateModalChech = this.changeStateModalChech.bind(this);
  }

  componentDidMount(){
    getBrandsData().then( response =>  this.setState({ brands: response.data.attributes}) )
    technicalOptions('size').then(response => this.setState({ sizes: response.data.attributes.options }))
    technicalOptions('package').then(response => this.setState({ packages: response.data.attributes.options }))
    technicalOptions('space').then(response => this.setState({ spaces: response.data.attributes.options }))
    getColors().then(response => this.setState({ colors: response }))
    getMaterials().then(response => this.setState({ materials: response }))
    getSpaceCodes().then(response => this.setState({ spacecodes: response }))
    getSizeCodes().then(response => this.setState({ sizecodes: response }))
  }

  inputEnabler(){
    this.setState({enabled: !this.state.enabled,});
  }

  componentWillReceiveProps(){
    this.setState({enabled: true});
  }

  check_data(){
    let array = [];
    let sku = document.getElementById('input_sku').value;
    let current_value
    document.querySelectorAll('.sheet_form input[type=text],.sheet_form input[type=number],.sheet_form select,#id_input_vein').forEach((select) => {
      let name = select.getAttribute('name');
      if(select.value === '' && typeof(this.props[name]) != 'object') {return}
      let value = (name === 'description' || name === 'web_name') ? select.value : select.value.toUpperCase().trim();
      value = (name === 'technical_products' && value != '' ) ? value.split(',') : value
      value = name === 'vein' ? select.checked ? 'vein' : 'no-vein' : value;
      current_value = typeof(this.props[name]) === 'object' ? this.props[name].join()  : this.props[name]?.trim()
      let change_condition = (typeof(this.props[name]) === 'object' && value != '') ? value.join() === current_value : value === current_value
      if(!change_condition){
        let nextState = {};
        let nextAttributes = {};
        nextState['type'] = 'product_change';
        nextState['id'] = sku;
        nextAttributes['sku'] = sku;
        nextAttributes['field_name'] = name;
        nextAttributes['field_detail'] = '';
        nextAttributes['old_value'] = current_value;
        nextAttributes['new_value'] = value;
        nextAttributes['title'] = select.getAttribute('placeholder') ? select.getAttribute('placeholder') : select.getAttribute('title');
        nextAttributes['updated_by'] = localStorage.getItem('email');
        
        nextState['attributes'] = nextAttributes;
        array.push(nextState);
      }
    });

    if(array.length!==0){
      this.setState({modalcheck: !this.state.modalcheck});
      this.setState({modalcheckData: array});
      this.setState({sku: sku});
    }else{
      toast.warn('No se ha realizado ningun cambio');
    }
  }

  changeStateModalChech(){
    this.setState({modalcheck: !this.state.modalcheck});
  }

  render() {
    let { brandRequired, descriptionRequired } = this.props.requiredSheet

    return(
      <form className='sheet_form container product-show-sheel was-validated mb-4'>
        <ModalCheck modalcheck={this.state.modalcheck} idTable='modaltablecheckproducts' data={this.state.modalcheckData} 
          changeStateModalChech={this.changeStateModalChech} sku={this.state.sku} id_btn_edit='btn_title_edit_sheet' 
          getProductData={this.props.getProductData}/>
        
          <TitleButton disabled={this.state.enabled} id_div_edit='div_title_edit_sheet' id_btn_edit='btn_title_edit_sheet' 
          title='FICHA TÉCNICA' id_div_save='div_title_send_sheet' 
          inputEnabler={this.inputEnabler} 
          check_data={this.check_data}
          title_edit='Ficha técnica'
          description='Para la confección de fichas automáticas'/>
        <div className='row'>
          <div className='col-md-5 mt-2'>
            <InputTextAttribute 
            measurement={true}
            title='Alto(cm)' name='height' id='id_input_height' disabled={this.state.enabled} 
            value={this.props.height.replace(',','.')} type='number'
            description='Alto o Largo del producto.'
            flexline_name=''
            required={false}
            calculatePercentOfInput = {this.props.calculatePercentOfInput}/>
          </div>

          <div className='col-md-5 mt-2'>
            <InputTextAttribute 
            measurement={true}
            title='Profundidad(cm)' name='lenght' id='id_input_lenght' disabled={this.state.enabled} 
            value={this.props.lenght.replace(',','.')} type='number'
            description='Profundidad o espesor del producto.'
            flexline_name=''
            required={false}
            calculatePercentOfInput = {this.props.calculatePercentOfInput}/>
          </div>

          <div className='col-md-5'>
            <InputTextAttribute 
            measurement={true}
            title='Ancho(cm)' name='width' id='id_input_width' disabled={this.state.enabled} 
            value={this.props.width.replace(',','.')} type='number'
            description='Ancho del producto'
            flexline_name=''
            required={false}
            calculatePercentOfInput = {this.props.calculatePercentOfInput}/>
          </div>   
 
          <div className='col-md-5'>
            {/* <InputTextAttribute title='Color' name='color' id='id_input_color' disabled={this.state.enabled} 
            value={this.props.color} type='text'
            description='Color del producto'
            flexline_name='analisisproducto27'
            required={false}
            calculatePercentOfInput = {this.props.calculatePercentOfInput}/> */}

            <SelectAtribute id='id_input_color'
              options={this.state.colors}
              value={this.props.color} title='Color' name='color'
              required={false}
              disabled={this.state.enabled} 
              description='Color del producto'
              calculatePercentOfInput={this.props.calculatePercentOfInput}
              flexline_name='analisisproducto27' />
          </div> 
          
          <div className='col-md-5'>
            {/* <InputTextAttribute title='Material' name='materiality' id='id_input_materiality' disabled={this.state.enabled} 
            value={this.props.materiality} type='text'
            description='Material de confección del producto'
            flexline_name='analisisproducto28'
            required={false}
            calculatePercentOfInput = {this.props.calculatePercentOfInput}/> */}

            <SelectAtribute id='id_input_materiality'
              options={this.state.materials}
              value={this?.props?.materiality} title='Material' name='materiality'
              required={false}
              disabled={this.state.enabled} 
              description='Material de confección del producto'
              calculatePercentOfInput={this.props.calculatePercentOfInput}
              flexline_name='analisisproducto28' />
          </div> 
          

          <div className='col-md-5'>
            <SelectAtribute 
            id='id_input_brand' 
            options={this.state.brands} 
            value={this.props.brand} 
            title='Marca' 
            name='brand' 
            disabled={this.state.enabled} 
            required={brandRequired}
            description='Marca del producto.'
            calculatePercentOfInput = {this.props.calculatePercentOfInput}
            flexline_name = ''/>
          </div>

          <div className='col-md-5'>
            <SelectAtribute 
            id='id_input_space_type' 
            options={ this.state.spacecodes } 
            value={this.props.space_type} 
            title='Tipo de espacio' 
            name='space_type' 
            disabled={this.state.enabled} 
            required={false}
            description='Indica el lugar deberia establecerse el producto'
            calculatePercentOfInput = {this.props.calculatePercentOfInput}
            flexline_name = 'analisisproducto29'/>
          </div>

          <div className='col-md-5'>
            <InputTextAttribute title='Colección' name='collection' id='id_input_collection' disabled={this.state.enabled} 
            value={this.props.collection} type='text'
            description=''
            flexline_name='analisisproducto16'/>
          </div> 

          <div className='col-md-5'>
            <SelectAtribute 
            id='id_input_size' 
            options={ this.state.sizecodes } 
            value={this.props.size} 
            title='Tamaño' 
            name='size' 
            disabled={this.state.enabled} 
            required={false}
            description='Indica el tamaño del producto'
            calculatePercentOfInput = {this.props.calculatePercentOfInput}
            flexline_name = ''/>
          </div>

          <div className='col-md-5'>
            <SelectAtribute 
            id='id_input_package_type' 
            options={ this.state.packages } 
            value={this.props.package_type} 
            title='Tipo de Empaque' 
            name='package_type' 
            disabled={this.state.enabled} 
            required={false}
            description='Indica que tipo de empaque necesita el producto'
            calculatePercentOfInput = {this.props.calculatePercentOfInput}
            flexline_name = ''/>
          </div>
          { this.props.family === 'TAPACANTOS Y CANTONERAS' ? 
            <div className='col-md-5'>
              <InputTextAttribute 
              measurement={true}
              title='Largo (metros)' name='technical_length' id='id_input_technical_length' disabled={this.state.enabled} 
              value={this.props.technical_length} type='number'
              description='Largo Tecnico del producto'
              flexline_name=''
              required={true}/>
            </div>  : ''}
          <div className='col-md-5 mt-2'>
            <SwitchRadioButton
            id='id_input_vein' 
            value= {this.props.vein === "vein"}
            title = 'Veta'
            name='vein' 
            disabled = { this.state.enabled }
            required={false}
            description='Indica si tiene veta.'
            flexline_name = '' 
            />
          </div>

          <div className='col-md-10 mt-2'>
            <WebName enabled={this.state.enabled} web_name={this.props.web_name} calculatePercentOfInput={this.props.calculatePercentOfInput}/>
          </div>

          <div className='col-md-10'>
            <TrixDescription title='Descripción' name='description' disabled={this.state.enabled}
            description='Descripción extenso del producto' value={this.props.description}
            required={descriptionRequired}
            calculatePercentOfInput = {this.props.calculatePercentOfInput}/>
          </div>
          <div className='col-md-10 mb-3'>
            <RelatedEdgebands
              title='Producto Tecnico Relacionado' name='technical_products'
              getProductData={this.props.getProductData} disabled={this.state.enabled}
              value={this.props.technical_products} required={false}
              technical_products={
                typeof(this.props.technical_products) === 'string'
                  ? []
                  : this.props.technical_products
              }
            />
            <small className='text-secondary'>Este campo es para relacionar productos en los sistemas de hbt, no es para las vistas comerciales. Si quiere relacionar un producto en el eCommerce, use el campo "Productos Relacionados"</small>
          </div>
          
          
          
          <div className='col-md-5'>
            <UpFilePDF id='id_file_data_sheet' value={this.props.documents} title='Ficha técnica' name='file_data_sheet' disabled={this.state.enabled}
            getProductData={this.props.getProductData} usage={'FichaTecnica'} sku={this.props.sku}/>
          </div>

          <div className='col-md-5'>
            <UpFilePDF id='id_file_user_manual' value={this.props.documents} title='Manual de uso' name='file_user_manual' disabled={this.state.enabled}
            getProductData={this.props.getProductData} usage={'ManualUso'} sku={this.props.sku}/>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  requiredSheet: state.elementRequiredReducer.sheet
})

const mapDispatchToProps = (dispach) => ({
})

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(Sheet)

export default component;
